import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

import Layout from "../components/layout";
import { linkResolver } from "../utils/linkResolver";

interface Props {
  isPreview: boolean;
  isLoading: boolean;
}

const PreviewPage: any = ({ isPreview, isLoading }: Props) => {
  if (isPreview === false) return <div>"Not a preview!"</div>;

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME!,
  linkResolver: linkResolver,
});
